import axios from 'axios';

export const makePostRequest = (route, token, data, multipart = null) => {

  const options = {
    method: 'post',
    headers: {
      'Content-type': multipart ? 'multipart/form-data' : 'application/json',
      'x-functions-key': process.env.REACT_APP_ADMIN_KEY_FOR_ALL_FUNCTIONS,
      'Authorization': token,
    },
    data,
    url: `${process.env.REACT_APP_BASE_URL}/${route}`
  };
  return axios(options);
};
