import React, { Component } from 'react';
import Header from '../layout/header/Header';
import Content from '../layout/content/Content';
import Modal from '../components/modal/Modal';
import queryString from 'query-string';

import EnglishJson from '../languages/English.json';
import GermanJson from '../languages/German.json';

import { makePostRequest } from '../api/requests';
import jwt from 'jwt-decode';

class HomePage extends Component {
  state = {
    open: false,
    view: true,
    alternativeModal: false,
    activateErrorModal: false,
    errorModalType: 'error',
    errorModalTextData: '',
    token: '',
    gdprText: false,
    gdprAccepted: false,
    activeLanguage: 'en',
    languageData: false,
    modalType: 'gdpr',
    submitting: false,
    thankYouText: '',
    introductionText: '',
    modalPicture: '',
  };

  componentDidMount() {
    let sessionToken = sessionStorage.getItem('token');
    (this.props.location || sessionToken) && this.setTokenData();
  }

  loadTranslationsFromServer = (token) => {
    let outboundData = {
      fields: ['PIC_FE_Gdpr', 'PIC_FE_Thank_you', 'PIC_FE_Camera_Instructions']
    };

    const submitRequest = makePostRequest('getSpecificDataFromDB', token, outboundData);
    submitRequest
      .then(response => {
        if (response.data.status) {
          let gdprMessage = response.data.message[0];
          let gdprMessageContent = JSON.parse(gdprMessage.value);

          let thankYouText = response.data.message[1];
          let thankYouTextContent;
          try {
            thankYouTextContent = JSON.parse(thankYouText.value);
          } catch (e) {
            console.log('Thank you message error with JSON format of translation, fallback to local version: ', e);
            thankYouTextContent = {
              en: EnglishJson.finalThankYouText,
              de: GermanJson.finalThankYouText,
            };
          }

          let cameraIntroductionText = response.data.message[2];
          let cameraIntroductionContent;
          try {
            cameraIntroductionContent = JSON.parse(cameraIntroductionText.value);
          } catch (e) {
            console.log('Camera Introduction error with JSON format of translation, fallback to local version: ', e);
            cameraIntroductionContent = {
              en: EnglishJson.cameraIntroductionText,
              de: GermanJson.cameraIntroductionText,
            };
          }

          this.setState({ gdprText: gdprMessageContent, thankYouText: thankYouTextContent, introductionText: cameraIntroductionContent });
        }
      })
      .catch(err => {
        let fallback = {
          en: this.state.languageData.translationAPIFallback,
          de: this.state.languageData.translationAPIFallback
        };
        this.setState({ gdprText: fallback });
        console.log(err);
      });
  };

  handleOpen = (openModal) => {
    this.setState({ open: openModal });
  };

  currentViewCallback = (callback) => {
    this.setState({ view: callback });

    if (callback === 'tutorial') {
      this.setState({ open: true });
    }
  };

  activateErrorModal = (data) => {
    this.setState({ activateErrorModal: data });
  };

  changeErrorModalType = (data) => {
    this.setState({ errorModalType: data });
  };

  updateErrorModalText = (data) => {
    this.setState({ errorModalTextData: data });
  };

  updateActiveLanguage = (data) => {
    this.setState({ activeLanguage: data });
  };

  updateLanguageData = (data) => {
    this.setState({ languageData: data });
  };

  updateModalType = (type) => {
    this.setState({ modalType: type });
  };

  updateGdprAccepted = (data) => {
    this.setState({ gdprAccepted: data });
  };

  updateSubmitting = (data) => {
    this.setState({ submitting: data });
  };

  updateModalPicture = (picture) => {
    this.setState({ modalPicture: picture });
  };

  removeSessionToken = () => {
    sessionStorage.removeItem('token');
  };

  

  setTokenData = () => {
    const { history, location: { search } } = this.props;
    const queryParams = queryString.parse(search);

    const token = queryParams.token;
    const sessionToken = sessionStorage.getItem('token');
    let failedDecode = false;

    if(token){
      try {
        // valid token format
        jwt(token);
      } catch(error) {
        // invalid token format
        failedDecode = true;
      }
      if(!failedDecode){
        sessionStorage.setItem('token', token);
        this.setState({ token: token });
        this.loadTranslationsFromServer(token);
      }
      history.replace('/');
    } else if(sessionToken) {
      this.setState({ token: sessionToken });
      this.loadTranslationsFromServer(sessionToken);
    }

    if ((token || sessionToken) && !failedDecode) {
      setTimeout(() => {
        !this.state.gdprAccepted && this.handleOpen(true);
      }, 1500);
    }
  };

  render() {
    const view = this.state.view;

    return (

      <div style={{ height: '100vh' }}>

        <Header
          intro={this.state.view}
          openModal={this.handleOpen}
          open={this.state.open}
          activeLanguage={this.state.activeLanguage}
          updateActiveLanguage={this.updateActiveLanguage}
          modalType={this.state.modalType}
          updateModalType={this.updateModalType}
          token={this.state.token}
        />

        <Modal
          openModal={this.handleOpen}
          open={this.state.open}
          view={view}
          currentView={this.currentViewCallback}
          alternativeModal={this.state.alternativeModal}
          activateErrorModal={this.state.activateErrorModal}
          updateActivateErrorModal={this.activateErrorModal}
          updateErrorModalText={this.state.errorModalTextData}
          type={this.state.errorModalType}
          gdprText={this.state.gdprText}
          activeLanguage={this.state.activeLanguage}
          updateActiveLanguage={this.updateActiveLanguage}
          modalType={this.state.modalType}
          updateModalType={this.updateModalType}
          gdprAccepted={this.state.gdprAccepted}
          updateGdprAccepted={this.updateGdprAccepted}
          submitting={this.state.submitting}
          updateSubmitting={this.updateSubmitting}
          modalPicture={this.state.modalPicture}
          updateModalPicture={this.updateModalPicture}
        />
        
        <Content
          view={view}
          currentView={this.currentViewCallback}
          activateErrorModal={this.activateErrorModal}
          changeErrorModalType={this.changeErrorModalType}
          updateErrorModalText={this.updateErrorModalText}
          activeLanguage={this.state.activeLanguage}
          updateActiveLanguage={this.updateActiveLanguage}
          languageData={this.state.languageData}
          updateLanguageData={this.updateLanguageData}
          token={this.state.token}
          gdprAccepted={this.state.gdprAccepted}
          submitting={this.state.submitting}
          updateSubmitting={this.updateSubmitting}
          removeSessionToken={this.removeSessionToken}
          thankYouText={this.state.thankYouText}
          introductionText={this.state.introductionText}
          updateModalType={this.updateModalType}
          modalType={this.state.modalType}
          open={this.state.open}
          openModal={this.handleOpen}
          modalPicture={this.state.modalPicture}
          updateModalPicture={this.updateModalPicture}
        />
      </div>
    );
  }
}

export default HomePage;
