import React, { Component } from 'react';
import Header from './Header';

class HeaderContainer extends Component {
  state = {
    text1: '?',
    text2: 'GDPR',
    cameraAllowed: false,
  };

  updateActiveLanguage = (data) => {
    this.props.updateActiveLanguage(data);
  };

  updateModalType = (type) => {
    this.props.updateModalType(type);
  };

  render() {
    return (
      <div style={{ height: '10vh' }}>
        <Header
          button={this.state}
          openModal={this.props.openModal}
          open={this.props.open}
          intro={this.props.intro}
          activeLanguage={this.props.activeLanguage}
          updateActiveLanguage={this.updateActiveLanguage}
          modalType={this.props.modalType}
          updateModalType={this.updateModalType}
          token={this.props.token}
        />
      </div>
    );
  }
}

export default HeaderContainer;
