import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { Button } from '@material-ui/core';
import Spinner from '../spinner/Spinner';
import EnglishJson from '../../languages/English.json';
import GermanJson from '../../languages/German.json';

const smallModalStyles = (theme) => ({
  paper: {
    position: 'absolute',
    width: 800,
    backgroundColor: 'rgba(250,250,250,1)',
    outline: 'none',
    padding: theme.spacing(2, 4, 3),
    textAlign: 'center',
    [ theme.breakpoints.down('md') ]: {
      width: '240px',
    },
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  button: {
    marginTop: '0',
  },
});

class ModalSmall extends Component {
  state = {
    open: false,
    errorTitle: this.props.activeLanguage === 'en' ? EnglishJson.cameraNotAllowed : GermanJson.cameraNotAllowed,
    errorText: this.props.activeLanguage === 'en' ? EnglishJson.cameraNotAllowedText : GermanJson.cameraNotAllowedText,
    submitTitle: this.props.activeLanguage === 'en' ? EnglishJson.submitProcessing : GermanJson.submitProcessing,
    submitText: this.props.activeLanguage === 'en' ? EnglishJson.submitProcessingText : GermanJson.submitProcessingText,
    tokenTitle: this.props.activeLanguage === 'en' ? EnglishJson.tokenTitle : GermanJson.tokenTitle,
    tokenText: this.props.activeLanguage === 'en' ? EnglishJson.tokenText : GermanJson.tokenText,
    showSpinner: true,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      !prevProps.activateErrorModal &&
      this.props.activateErrorModal &&
      !this.state.open
    ) {
      this.setState({ open: true });
    }

    if (
      prevProps.activateErrorModal &&
      !this.props.activateErrorModal &&
      this.state.open
    ) {
      this.setState({ open: false });
    }

    if (this.state.open && this.props.activateErrorModal) {
      if (
        !prevProps.errorModalTextData !== this.props.errorModalTextData &&
        !prevProps.errorModalTextData &&
        this.props.errorModalTextData
      ) {
        this.setState({
          submitTitle: this.props.errorModalTextData.title,
          submitText: this.props.errorModalTextData.text,
        });
      }
    }

  }

  handleOpen = (openModal) => {
    this.setState({ open: openModal });
    if(!openModal) {
      this.props.updateActivateErrorModal(false);
    }
  };

  handleBackdropClick = () => {
    this.props.type === 'image' && this.handleOpen(false);
  };

  render() {
    const { classes, type, submitting, modalPicture } = this.props;

    const body = (
      <div className={classes.paper}>
        {
          type !== 'image' &&
            <h2 id="simple-modal-title">
              {type === 'error' && this.state.errorTitle}
              {type === 'submit' && this.state.submitTitle}
              {type === 'token' && this.state.tokenTitle}
            </h2>
        }
        <p id="simple-modal-description">
          {type === 'error' && this.state.errorText}
          {type === 'token' && this.state.tokenText}
        </p>

        {
          type === 'image' && modalPicture &&
            <div>
              <img
                style={{ width: '800px', height: '600px' }}
                src={modalPicture}
                alt={'modal-zoom--' + type}
              />
            </div>
        }

        {
          type === 'submit' && submitting &&
            <div className="spinner-container">
              <Spinner show={this.state.showSpinner}/>
            </div>
        }


        {
          type !== 'submit' && type !== 'image' &&
            <Button
              onClick={() => this.handleOpen(false)}
              disabled={submitting}
              size="small"
              variant="outlined"
              disableElevation={false}
            >
              Close
            </Button>
        }

      </div>
    );

    return (
      <div>
        <Modal
          disableBackdropClick
          open={this.state.open}
          onClose={() => this.handleOpen(false)}
          onBackdropClick={this.handleBackdropClick}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          {body}
        </Modal>
      </div>
    );
  }
}

export default withStyles(smallModalStyles)(ModalSmall);
