import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';

const modalStyles = (theme) => ({
  cards: {
    margin: '20px 0',
    minHeight: '300px',
  },
  [ theme.breakpoints.down('sm') ]: {
    cards: {
      margin: '10px 0',
      minHeight: '430px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      fontSize: '17px',
    },
    imageEx: {
      width: '60%',
    },
  },
});

class SingleModal extends Component {
  render() {
    const { singleProps, classes, singleRef } = this.props;

    return (
      <div
        id={singleProps.id}
        ref={singleRef}
      >
        <Grid item xs={12} lg={12} className={classes.cards}>
          <h2 className={classes.text}>{singleProps.text}</h2>
          <img className={classes.imageEx} src={singleProps.imageEx} edge="start" alt="imgModal"/>
        </Grid>
      </div>
    );
  }
}

export default withStyles(modalStyles)(SingleModal);
