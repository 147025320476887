import React, { Component } from 'react';
import BigImage from './BigImage';

class BigImageContainer extends Component {

  sendDeleteImageToParent = () => {
    this.props.dataCallback(this.props.type);
  };

  handleImageClick = (image) => {
    this.props.handleImageClick(image);
  };

  render() {
    const { imageSrc, type } = this.props;
    return (
      <div>
        <BigImage
          imageSrc={imageSrc}
          type={type}
          dataCallback={this.sendDeleteImageToParent}
          handleImageClick={this.handleImageClick}
        />
      </div>
    );
  }
}

export default BigImageContainer;