import React, { Component } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';
import SingleModal from './SingleModal';

import PersonIcon from '@material-ui/icons/Person';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';

import id from '../../assets/id.png';
import person from '../../assets/photoInstruct.png';
import camera from '../../assets/camera.png';

import Checkbox from '@material-ui/core/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';

import EnglishJson from '../../languages/English.json';
import GermanJson from '../../languages/German.json';

import Spinner from '../spinner/Spinner';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const bigModalStyles = (theme) => ({
  paper: {
    position: 'relative',
    width: 990,
    height: 600,
    outline: 'none',
    textAlign: 'center',
    backgroundColor: 'rgba(104,104,104,0.9)',
    color: '#fff',
    [ theme.breakpoints.down('md') ]: {
      width: '600px',
    },
    [ theme.breakpoints.down('sm') ]: {
      width: '300px',
      height: 'auto',
    },
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  headerTut: {
    position: 'relative',
    width: '100%',
    height: '100px',
    zIndex: '9',
    borderBottom: '1px solid rgba(250,250,250, 0.2)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [ theme.breakpoints.down('sm') ]: {
      height: '80px',
      justifyContent: 'left',
    },
  },
  headerGdpr: {
    position: 'relative',
    width: '100%',
    height: '100px',
    zIndex: '9',
    borderBottom: '1px solid rgba(250,250,250, 0.2)',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    [ theme.breakpoints.down('sm') ]: {
      height: '72px',
    },
  },
  paperContent: {
    position: 'relative',
    padding: '0px 76px ',
    height: '500px',
    boxSizing: 'border-box',
    outline: 'none',
    overflow: 'scroll',
    textAlign: 'center',
    [ theme.breakpoints.down('md') ]: {
      width: '600px',
    },
    [ theme.breakpoints.down('sm') ]: {
      width: '300px',
      padding: '0 15px',
      height: '440px',
    },
  },
  paperContentGdpr: {
    position: 'relative',
    padding: '0px 76px ',
    height: '400px',
    boxSizing: 'border-box',
    outline: 'none',
    overflow: 'scroll',
    textAlign: 'center',
    [ theme.breakpoints.down('md') ]: {
      width: '600px',
    },
    [ theme.breakpoints.down('sm') ]: {
      width: '300px',
      padding: '0 10px',
      height: '360px',
    },
  },

  wrap: {
    position: 'relative',
  },

  wrapGdpr: {
    position: 'relative',
    textAlign: 'left',
    padding: '10px 0 ',
  },

  closeButton: {
    position: 'absolute',
    right: '15px',
    zIndex: '9',
    [ theme.breakpoints.down('xs') ]: {
      right: '0px',
      top: '13px',
      padding: '0px',
    },
  },
  startButtonTut: {
    position: 'absolute',
    right: '-35px',
    bottom: '15px',
    [ theme.breakpoints.down('xs') ]: {
      right: '0px',
      bottom: '18px',
      width: '120px',
      height: '55px',
      fontSize: '20px',
    },
  },

  startButtonGdpr: {
    position: 'absolute',
    right: '35px',
    bottom: '20px',
    [ theme.breakpoints.down('xs') ]: {
      bottom: '0px',
      width: '120px',
      right: 'auto',
      height: '55px',
      fontSize: '20px',
      position: 'relative',
    },
    border: {
      border: '1px solid white',
    },
  },

  headerBtn: {
    [ theme.breakpoints.down('xs') ]: {
      padding: '2px !important',
    },
  },
  gdprFooter: {
    position: 'relative',
    padding: '0 76px',
    height: '100px',
    zIndex: '9',
    borderTop: '1px solid rgba(250,250,250, 0.2)',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    [ theme.breakpoints.down('sm') ]: {
      padding: '15px',
      height: 'auto',
      display: 'block',
    },
  },
  formControl: {
    marginLeft: '20px',
    border: '1px solid rgba(250,250,250,0.5)',
    borderRadius: '100%',
    height: '62px',
    width: '63px',
    [ theme.breakpoints.down('sm') ]: {
      height: '50px',
      width: '50px',
      marginRight: '4px !important',
      fontSize: '11px',
      lineHeight: '35px',
    },
  },
  selectField: {
    color: '#fafafa',
    fontSize: '14px',
    lineHeight: '24px',
    [ theme.breakpoints.down('sm') ]: {
      fontSize: '11px',
      lineHeight: '11px',
    },

  },
  icon: {
    fill: '#fff',
    fontSize: '26px',
    [ theme.breakpoints.down('sm') ]: {
      right: '1px',
    },
  },
  selected: {
    border: '1px solid red',
  },
});

const parse = require('html-react-parser');

class ModalBig extends Component {
  state = {
    type: 'gdpr', //gdpr or tutorial
    showSpinn: 'true',
  };

  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  componentWillUnmount() {
  }

  closeModal = (data) => {
    if (this.props.gdprAccepted && this.props.modalType === 'gdpr' ) {
      if(this.props.view === true){
        this.props.updateModalType('tutorial');
      } else {
        data = false;
      }
      
    }
    
    this.props.openModal(data);
    this.props.view === 'tutorial' && !data && this.props.currentView('takingID');

    if (this.props.modalType === 'tutorial') {
      this.props.currentView('takingID');
    }

  };


  changeLanguage = (event) => {
    this.props.updateActiveLanguage(event.target.value);
  };

  acceptGdpr = (event) => {
    this.props.updateGdprAccepted(event.target.checked);
  };

  render() {
    const { classes, activeLanguage, gdprAccepted, modalType } = this.props;

    const gdprTextRender = activeLanguage === 'en' ? this.props.gdprText.en : this.props.gdprText.de;

    const languageData = activeLanguage === 'en' ? EnglishJson : GermanJson;

    const bigModalContent = [
      {
        text: languageData.tutorialText1,
        imageEx: id,
        id: 'card',
      },
      {
        text: languageData.tutorialText2,
        imageEx: person,
        id: 'person',
      },
      {
        text: languageData.tutorialText3,
        imageEx: camera,
        id: 'camera',
      },
    ];

    const refs = bigModalContent.reduce((acc, value) => {
      acc[ value.id ] = React.createRef();
      return acc;
    }, {});

    const handleClickNavigation = (id) => {
      const obj = refs[ id ];
      obj.current.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
      });
    };

    return (
      <div>
        <Modal
          disableBackdropClick
          open={Boolean(this.props.open)}
          onClose={() => this.closeModal(false)}
        >

          <div className={classes.paper}>
            {
              modalType === 'tutorial' ? (
                <div className={classes.headerTut}>
                  <IconButton
                    color="primary"
                    onClick={() => handleClickNavigation('card')}
                  >
                    <FeaturedPlayListIcon style={{ fontSize: 40 }}/>
                  </IconButton>

                  <IconButton
                    color="primary"
                    onClick={() => handleClickNavigation('person')}
                  >
                    <PersonIcon style={{ fontSize: 40 }}/>
                  </IconButton>

                  <IconButton
                    color="primary"
                    onClick={() => handleClickNavigation('camera')}
                  >
                    <PhotoCameraIcon style={{ fontSize: 40 }}/>
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={() => this.closeModal(false)}
                    className={classes.closeButton}
                  >
                    <CloseIcon/>
                  </IconButton>
                </div>
              ) : (
                <div className={classes.headerGdpr}>
                  <FormControl
                    variant="outlined"
                    className={classes.formControl}
                  >
                    <Select
                      labelId="language-header-select-label"
                      id="language-header-select"
                      className={classes.selectField}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}
                      value={activeLanguage}
                      onChange={this.changeLanguage}
                    >
                      <MenuItem value={'en'}>EN</MenuItem>
                      <MenuItem value={'de'}>DE</MenuItem>
                    </Select>
                  </FormControl>
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    enterDelay={1500}
                    title={languageData.tooltipClose}>
                    <IconButton
                      aria-label="delete"
                      color="primary"
                      onClick={() => this.closeModal(false)}
                      className={classes.closeButton}
                    >
                      <CloseIcon/>
                    </IconButton>
                  </Tooltip>
                </div>
              )
            }

            <div className={this.props.modalType === 'gdpr' ? classes.paperContentGdpr : classes.paperContent}>
              <div className={this.props.modalType === 'gdpr' ? classes.wrapGdpr : classes.wrap}>
                {
                  modalType === 'tutorial' ? (
                    <div>
                      {bigModalContent.map((singleContent) => (
                        <SingleModal
                          singleProps={singleContent}
                          key={singleContent.id}
                          singleRef={refs[ singleContent.id ]}
                        ></SingleModal>
                      ))}
                    </div>
                  ) : (
                    <div>
                      {parse(gdprTextRender ? gdprTextRender : '')}
                      {!this.props.gdprText &&
                      <div className="spinner-container"><Spinner show={this.state.showSpinn}/></div>}
                    </div>
                  )
                }

                {
                  this.props.modalType !== 'gdpr' &&
                  <Button
                    onClick={() => this.closeModal(false)}
                    color="secondary"
                    variant="outlined"
                    disableElevation={false}
                    className={classes.startButtonTut}
                    size="large"
                  >
                    Start
                  </Button>
                }

              </div>
            </div>
            {this.props.modalType === 'gdpr' &&
            <div className={classes.gdprFooter}>
              {this.props.gdprText &&
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    value="off"
                    style={{ color: 'white' }}
                    control={<Checkbox
                      size="small"
                      style={{ color: '#fff' }}
                      color="primary"/>}
                    label={languageData.gdprCheckboxText}
                    labelPlacement="end"
                    icon={<span className={classes.icon}/>}
                    checked={gdprAccepted}
                    onChange={this.acceptGdpr}
                  />
                </FormGroup>
              </FormControl>
              }

              <Button
                onClick={() => this.closeModal(true)}
                color="secondary"
                variant="outlined"
                disabled={!gdprAccepted}
                disableElevation={false}
                className={classes.startButtonGdpr}
                size="large"
              >
                Start
              </Button>
            </div>
            }
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(bigModalStyles)(ModalBig);
