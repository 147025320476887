import { createMuiTheme } from '@material-ui/core/styles';
import grey from '@material-ui/core/colors/grey'; 


const theme = createMuiTheme({
  palette: {
    primary: {
      main: grey[50],
    },
    secondary: {
      main: '#fafafa',
    },
    action: {
      disabledBackground: grey[50],
    }
  },
  status: {
    danger: 'orange',
  },
  overrides: {
    // Style sheet name ⚛️
    MuiAppBar: {
      colorDefault: {
        backgroundColor: 'transparent',
      },
    },
    MuiInput: {
      underline: {
        '&::before': {
          borderBottom:'2px solid #fafafa !important'
        },
        '&:hover:before': {
          borderBottom:'2px solid #fafafa  !important'
        }
      }
    },
    MuiSvgIcon: {
      root:{
        fontSize: '3.2rem'
      }
    },
    MuiButton: {
      root: {
        minWidth: '20px'
      },
      outlinedSizeSmall: {
        fontSize: '1.2em',
        width: '130px',
        height: '56px',
        borderRadius: '10px',
      },
      outlinedSizeLarge: {
        fontSize: '1.5em',
        width: '150px',
        height: '70px',
        borderRadius: '10px',
      },
      outlinedSizeLargeSubmit: {
        fontSize: '1.5em',
        width: '200px',
        height: '70px',
        borderRadius: '10px',
      },
      outlinedSecondary: {
        border: '3px solid rgba(250, 250, 250, 0.8)'
      },
      notchedOutline: {
        border: '3px solid yellow'
      }
    },
    MuiSelect: {
      outlined: {
        paddingRight: '26px !important',

      }
    },

    MuiInputBase:{
      root: {
        color: '#fafafa'
      },
      input: {
        width: '215px'
      }
    },
    MuiOutlinedInput: {

      notchedOutline: {
        borderColor: 'transparent !important',
      }
    },
    MuiPaper: {
      elevation4: {
        boxShadow: 'none'
      }
    },

    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#363636',
        borderBottom: '1px solid #fafafa'
      }
    },
    MuiPickersBasePicker: {
      pickerView: {
        backgroundColor: '#363636'
      }
    },
    MuiDialogActions: {
      root: {
        backgroundColor: '#363636',
        borderTop: '1px solid #fafafa'
      }
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#fafafa !important'
      },
      toolbarBtnSelected: {
        color: '#fafafa'
      }
    },
    MuiPickersYear: {
      root: {
        color: '#fafafa'
      },
      yearDisabled:{
        color: 'gray'
      }
    },
    MuiPickersMonthSelection: {
      container: {
        color: '#fafafa'
      }
    },
    MuiPickersMonth: {
      monthDisabled: {
        color: '#fafafa'
      }
    },
    MuiPickersSlideTransition: {
      transitionContainer: {
        color: '#fafafa'
      }
    },
    MuiPickersCalendarHeader: {
      dayLabel: {
        color: '#fafafa'
      },
      iconButton: {
        backgroundColor: 'transparent !important',
        color: '#fafafa'
      }
    },
    MuiPickersDay: {
      day: {
        color: '#fafafa'
      },
      dayDisabled: {
        color: '#fafafa'
      }
    },
   
  

  },
});

export default theme;