import React, { Component } from 'react';
import spinner from '../../assets/spinner.png';

class Spinner extends Component {

  render() {

    const { show } = this.props;

    return (
      show ? <img src={spinner} edge="start" alt="imgModal" className="spinner"/> : ''
    );

  }
}

export default Spinner;

