import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import personIcon from '../../../assets/personIcon.png';

class BigImage extends Component {

  sendDeleteImageToParent = () => {
    this.props.dataCallback(this.props.type);
  };

  handleImageClick = () => {
    this.props.handleImageClick(this.props.imageSrc);
  };

  render() {

    const { imageSrc, type } = this.props;

    return (
      <div>
        <Box
          display="flex"
          mb={3}
          css={{ height: 'auto', minHeight: 280 }}>

          <Grid container spacing={0}>

            <Grid item xs={12} sm={12} md={12}>
              <Box
                css={{
                  width: 292,
                  height: 'auto',
                  minHeight: 218,
                  border: 1,
                  borderColor: '#fff',
                  borderStyle: 'solid',
                  margin: 'auto',
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column',
                }}
              >

                {
                  imageSrc ? (
                    ('')
                  ) : <img src={personIcon} alt="imgModal"/>
                }

                {
                  imageSrc ? (
                    ('')
                  ) : <p style={{ color: '#fff' }}>{type === 'frontside' ? ('Frontside') : ('Backside')} ID</p>

                }

                {
                  imageSrc ? (
                    <img
                      style={{ width: '292px', height: 'auto', cursor: 'pointer' }}
                      src={imageSrc}
                      alt={'preview-key-' + type}
                      onClick={this.handleImageClick}
                    />
                  ) : ('')}
                {
                  imageSrc ? (
                    <IconButton
                      onClick={this.sendDeleteImageToParent}
                      style={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        padding: '4px',
                        backgroundColor: '#363636',
                        '&:hover': {
                          background: '#363636',
                        },
                      }}
                      aria-label="delete"
                      color="primary">
                      <DeleteOutlinedIcon style={{ fontSize: 24 }}/>
                    </IconButton>
                  ) : ('')
                }
              </Box>
            </Grid>
          </Grid>

        </Box>
      </div>
    );
  }
}

export default BigImage;