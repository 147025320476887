import React, { Component } from 'react';
import logo from '../../assets/LogoWhiteSmall.png';
import { Button, Typography } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import EnglishJson from '../../languages/English';
import GermanJson from '../../languages/German';
import LogoComponent from './LogoComponent';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  headerLogo: {
    height: '60px',
  },
  title: {
    flexGrow: 1,
  },
  headerButtons: {
    fontSize: '13px',
    width: '64px',
    height: '64px',
    borderRadius: '100%',
    lineHeight: '50px',
    [ theme.breakpoints.down('sm') ]: {
      height:'50px',
      width:'50px',
      marginRight: '4px !important',
      fontSize: '11px',
      lineHeight: '35px'
    },
  },
  formControl: {
    marginRight: '8px',
    border: '1px solid rgba(250,250,250,0.5)',
    borderRadius: '100%',
    height: '62px',
    width: '63px',
    [ theme.breakpoints.down('sm') ]: {
      height:'50px',
      width:'50px',
      marginRight: '4px !important',
      fontSize: '11px',
      lineHeight: '35px'
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectField: {
  color: '#fafafa',
  fontSize:'14px',
  lineHeight:'24px',
  [ theme.breakpoints.down('sm') ]: {
    fontSize:'11px',
    lineHeight:'11px',
  },

  },
  icon: {
    fill: '#fff',
    fontSize: '26px',
    [ theme.breakpoints.down('sm') ]: {
      right: '1px'
    },
  },
  selected: {
    border:'1px solid red'
  }
});

class Header extends Component {
  clickMethod = (type) => {
    if(!this.props.open) {
      this.props.updateModalType(type);
      this.props.openModal(true);
    } 
  };

  changeLanguage = (event) => {
    this.props.updateActiveLanguage(event.target.value);
  };

  render() {
    const logoData = {
      img: logo,
      edge: 'start',
      alt: 'Logo',
    };

    const { button, classes, activeLanguage, token } = this.props;

    const languageData = activeLanguage === 'en' ? EnglishJson : GermanJson;

    return (
      <div className={classes.root}>
        <AppBar color="default" position="absolute" style={{ paddingTop: 20 }}>
          <Toolbar disableGutters={true}>
            {
              this.props.intro !== true ? (
                <LogoComponent
                  img={logoData.img}
                  edge={logoData.edge}
                  alt={logoData.alt}
                />
              ) : ('')
            }

            <Typography className={classes.title}>.</Typography>
            <div>
              <FormControl
                variant="outlined"
                className={classes.formControl}
              >
                <Select
                  labelId="language-header-select-label"
                  id="language-header-select"
                  className={classes.selectField}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}
                  value={activeLanguage}
                  onChange={this.changeLanguage}
                >
                  <MenuItem value={'en'}>EN</MenuItem>
                  <MenuItem value={'de'}>DE</MenuItem>
                </Select>
              </FormControl>
              <Tooltip
                disableFocusListener
                disableTouchListener
                enterDelay={1500}
                title={languageData.tooltipTerms}>
                <span>
                  <Button
                    className={classes.headerButtons}
                    variant="outlined"
                    onClick={() => this.clickMethod('gdpr')}
                    disableElevation={true}
                    color="primary"
                    style={{ marginRight: 10, color: '#fafafa' }}
                    disabled={!token}
                  >
                  {button.text2}
                </Button>
                </span>
              </Tooltip>

              {
                this.props.intro !== true ? (
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    enterDelay={1500}
                    title={languageData.tooltipHelp}>
                    <Button
                      onClick={()=> this.clickMethod('tutorial')}
                      style={{ fontSize: 22 }}
                      color="primary"
                      className={classes.headerButtons}
                      variant="outlined"
                      disableElevation={true}
                    >
                      {button.text1}
                    </Button>
                  </Tooltip>
                ) : ('')
              }
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

export default withStyles(useStyles)(Header);
