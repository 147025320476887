import React, { Component } from 'react';
import WebCamCapture from './WebCamCapture';

class CameraContainer extends Component {

  state = {
    currentAction: 'frontside',
    currentImageSrc: '',
    imagesArray: [],
    frontsideImage: '',
    backsideImage: '',
    validState: false,
    errorState: false,
  };

  sendImageDataToParent = (childData) => {
    this.props.processCurrentImage(childData);
  };

  updateView = (data) => {
    this.props.updateView(data);
  };

  prepareDataBeforeSubmitCallback = () => {
    this.props.prepareDataBeforeSubmitCallback();
  };

  mediaStreamSuccess = () => {
    this.props.mediaStreamSuccess();
  };

  mediaStreamError = () => {
    this.props.mediaStreamError();
  };

  updateVideoDeviceID = (data) => {
    this.props.updateVideoDeviceID(data);
  };

  render() {

    return (
      <div>
        <WebCamCapture
          key={this.props.videoDeviceID}
          videoDeviceID={this.props.videoDeviceID}
          updateVideoDeviceID={this.updateVideoDeviceID}
          videoDevices={this.props.videoDevices}
          processCurrentImage={this.sendImageDataToParent}
          contentView={this.props.view}
          updateView={this.updateView}
          frontSideImage={this.props.frontSideImage}
          backSideImage={this.props.backSideImage}
          imagesArray={this.props.imagesArray}
          submitFinished={this.props.submitFinished}
          submitButtonText={this.props.submitButtonText}
          submitButtonClass={this.props.submitButtonClass}
          prepareDataBeforeSubmitCallback={this.prepareDataBeforeSubmitCallback}
          mediaStreamSuccess={this.mediaStreamSuccess}
          mediaStreamError={this.mediaStreamError}
          activeLanguage={this.props.activeLanguage}
        />
      </div>
    );
  }
}

export default CameraContainer;