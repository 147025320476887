import React, { Component } from 'react';
//import logoBig from '../../assets/logoBig.png';
import logoBig from '../../assets/LogoWhite.png';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import { Button } from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import PersonIcon from '@material-ui/icons/Person';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FaceIcon from '@material-ui/icons/Face';
import FeaturedPlayListIcon from '@material-ui/icons/FeaturedPlayList';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import EnglishJson from '../../languages/English.json';
import GermanJson from '../../languages/German.json';
import { COUNTRIES } from '../../utils/constants';

import { makePostRequest } from '../../api/requests';
import { CameraContainer } from '../../components/camera';
import BigImageContainer from '../../components/imagePreviews/bigImage/BigImageContainer';

import CloseIcon from '@material-ui/icons/Close';

import jwt from 'jwt-decode';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const parse = require('html-react-parser');

const useStyles = (theme) => ({
  root: {
    padding: theme.spacing(1),
    [ theme.breakpoints.down('sm') ]: {
      width: '300px',
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  listUL: {
    display: 'flex',
    flexFlow: 'wrap',
    padding: '10px',
    height: '480px',
    width: '570px',
    overflow: 'scroll',
    placeContent: 'flex-start',
    border: '2px solid #fafafa',
    scrollbarWidth: 'none',
    [ theme.breakpoints.down('sm') ]: {
      width: '290px',
      height: '300px',
      padding: '4px',
      justifyContent: 'center',
    },
  },
  listEl: {
    width: '130px',
    height: 'auto',
    margin: '6px',
    padding: '0',
    justifyContent: 'center',
    alignItems: 'center',
    [ theme.breakpoints.down('xs') ]: {
      margin: '3px',
    },
  },
  emptyHolder: {
    width: '129px',
    height: '97px',
    border: '1px solid white',
    position: 'relative',
    margin: '6px',
    padding: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [ theme.breakpoints.down('sm') ]: {
      margin: '3px',
    },
  },
  deleteIcon: {
    position: 'absolute',
    top: '2px',
    right: '2px',
    padding: '4px',
    backgroundColor: '#363636',
    '&:hover': {
      background: '#363636',
    },
  },
  borderActive: {
    border: '1px solid white',
  },
  viewChange: {
    marginBottom: '10px',
    [ theme.breakpoints.down('sm') ]: {
      marginTop: '20px',
      paddignBottom: '0px',
    },
  },
  thankYou: {
    padding: theme.spacing(1),
    [ theme.breakpoints.down('sm') ]: {
      width: '300px',
    },
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
    fontSize: '40px',
  },
  idTut: {
    minHeight: 'auto',
    position: 'relative',
    margin: '0px auto 20px',
    width: window.innerWidth >= 480 ? 600 : 290,
    textAlign: 'left',
    backgroundColor: 'transparent',
    border: '1px solid #fafafa',
    [ theme.breakpoints.down('sm') ]: {
      fontSize: '12px',
    },
  },
  idTutClose: {
    position: 'absolute',
    right: '-19px',
    top: '-19px',
    padding: 2,
    borderRadius: '100%',
    border: '1px solid #fafafa',
    color: '#fafafa',
    backgroundColor: '#363636',
    '&:hover': {
      background: '#595959',
    },
  },
  formFields: {
    margin: 22,
    [ theme.breakpoints.down('sm') ]: {
      margin: 5,
    },
  },
  gdprBtn: {
    fontSize: '13px',
    width: '64px',
    height: '64px',
    borderRadius: '100%',
    lineHeight: '50px',
    [ theme.breakpoints.down('sm') ]: {
      height: '50px',
      width: '50px',
      marginRight: '4px !important',
      fontSize: '11px',
      lineHeight: '35px',
    },
  },
  selectField: {
    color: '#fafafa',
    fontSize: '14px',
    lineHeight: '24px',
    [ theme.breakpoints.down('sm') ]: {
      fontSize: '11px',
      lineHeight: '11px',
    },
  },
  formControl: {
    marginRight: '8px',
    border: '1px solid rgba(250,250,250,0.5)',
    borderRadius: '100%',
    height: '62px',
    width: '63px',
    [ theme.breakpoints.down('sm') ]: {
      height: '50px',
      width: '50px',
      marginRight: '4px !important',
      fontSize: '11px',
      lineHeight: '35px',
    },
  },
  icon: {
    fill: '#fff',
    fontSize: '26px',
    [ theme.breakpoints.down('sm') ]: {
      right: '1px',
    },
  },
  popupLists: {
    backgroundColor: '#686868',
    color: '#fff',

  },
  inputRoot: {
    margin: '22px',
    color: "purple",

    "& .MuiAutocomplete-endAdornment": {
      top: '-14px',
      "& .MuiIconButton-label": {
        color: '#fff'
      }
    }
  }
});

class Content extends Component {
  state = {
    cameraStatus: false,
    modalActive: false,
    view: 'intro', //intro, tutorial, takingID, takingImages, submitting, done
    tokenData: false,
    tokenLanguage: false,
    activeLanguage: 'en',
    languageData: false,
    currentAction: 'frontside', //intro or empty?, frontside, backside, images, submitting
    currentImageSrc: '',

    imagesArray: [],
    frontSideImage: '',
    backSideImage: '',

    participantName: '',
    participantLastName: '',
    participantEmail: '',
    participantCity: '',
    participantCountry: null,
    participantDate: null,

    submitting: false,
    submitFinished: false,
    idTutShow: true,
    videoDevices: [],
    videoDeviceID: 'default',
    introPass: true
  };

  componentDidMount() {
    this.decodeToken();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

    if (prevProps.view === true && this.props.view === 'takingID') {
      this.firstStepInitialization();
    }

    if (this.props.token && !this.state.tokenData) {
      this.decodeToken();
    }

    if (prevState.tokenData
      && this.state.tokenData
      && prevState.tokenLanguage
      && this.state.tokenLanguage
      && !this.state.languageData) {
      this.setLanguage();
    }

    if (prevProps.activeLanguage !== this.props.activeLanguage) {
      this.setLanguage(this.props.activeLanguage);
    }

    if (prevState.videoDevices.length === 0 && this.state.videoDevices.length === 0) {
      this.setVideoDevices();
    }
  }

  componentWillUnmount() {

  }

  firstStepInitialization = () => {
    this.setState({ view: 'takingID' });
    /*const cameraPermission = displayInitialVideoPopup();

    cameraPermission
      .then((permission) => {
        const cameraAllowedPromise = isCameraAllowed();

        cameraAllowedPromise
          .then((data) => {
            //this.setState({ cameraStatus: data.cameraAllowed });
            if (data.cameraAllowed) {
              this.activateErrorModal(false, 'error');
            }
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });*/
  };

  setVideoDevices = () => {
    if (this.state.videoDevices.length === 0) {
      navigator.mediaDevices.enumerateDevices().then((devices) => {
        let videoDevices = devices.filter((item) => item.kind === 'videoinput');
        this.setState({ videoDevices: videoDevices });
      });
    }
  };

  updateVideoDeviceID = (data) => {
    this.setState({ videoDeviceID: data });
  };

  setCurrentImage = (childData) => {
    this.setState({ currentImageSrc: childData });
    this.processCurrentImage(childData);
  };

  processCurrentImage = (callbackData) => {
    switch (this.state.currentAction) {
      case 'frontside':
        this.setState({ frontSideImage: callbackData, currentAction: 'backside' });
        break;
      case 'backside':
        if (!this.state.frontSideImage) {
          this.setState({ frontSideImage: callbackData, currentAction: 'backside' });
        } else {
          this.setState({ backSideImage: callbackData, currentAction: 'images' });
        }
        break;
      case 'images':
        if (this.state.view !== 'takingID' &&
          this.state.imagesArray.length < 20) {
          let imagesArray = [...this.state.imagesArray];
          imagesArray.unshift(callbackData);
          this.setState({ imagesArray: imagesArray });
        }
        break;
      default:
        break;
    }
  };

  setCurrentAction = (action) => {
    this.setState({ currentAction: action });
  };

  changeView = (view) => {
    this.setState({ view: view });
    this.props.currentView(view);
  };

  deleteIDImageFromChild = (callbackData) => {
    switch (callbackData) {
      case 'frontside':
        this.setState({ frontSideImage: '', currentAction: 'frontside' });
        break;
      case 'backside':
        this.setState({ backSideImage: '', currentAction: 'backside' });
        break;
      default:
        break;
    }
  };

  deleteSingleImageFromArray = (key) => {
    let imagesArray = this.state.imagesArray.filter((item, i) => i !== key);
    this.setState({ imagesArray: imagesArray });
  };

  activateErrorModal = (status, data = null) => {
    this.setState({ modalActive: status });
    if (data) {
      this.props.changeErrorModalType(data);
    }
    this.props.activateErrorModal(status);
  };

  updateErrorModalText = (data) => {
    let obj = {
      title: data.title,
      text: data.text,
    };
    this.props.updateErrorModalText(obj);
  };

  decodeToken = () => {
    if (this.props.token && !this.state.tokenData) {
      const decoded = jwt(this.props.token);
      let activeLang = 'en';
      let languageJSON;

      if(decoded.language && decoded.language !== 'en'){
        activeLang = decoded.language;
        languageJSON = GermanJson;
      } else {
        languageJSON = EnglishJson;
      }

      this.setState({
        tokenData: decoded,
        tokenLanguage: decoded.language,
        participantName: decoded.Participant_Firstname,
        participantLastName: decoded.Participant_Lastname,
        participantEmail: decoded.participantemail,
        languageData: languageJSON,
        activeLanguage: activeLang,
      });
      this.props.updateActiveLanguage(activeLang);
      this.activateErrorModal(false, 'error');
    } else {
      console.log('Sorry no token provided, so no token decoded! modal opened!');
      this.activateErrorModal(true, 'token');
    }
  };

  setLanguage(langString = null) {
    if (this.state.tokenData && this.state.tokenLanguage) {
      const activeLang = langString ? langString : this.state.activeLanguage;

      if (activeLang === 'en') {
        this.setState({ languageData: EnglishJson, activeLanguage: activeLang });
        this.props.updateLanguageData(EnglishJson);
      } else {
        this.setState({ languageData: GermanJson, activeLanguage: activeLang });
        this.props.updateLanguageData(GermanJson);
      }
      this.props.updateActiveLanguage(activeLang);
    }
  }

  setParticipantCountry = (data) => {
    this.setState({ participantCountry: data })
  };

  updateParticipantName = (event) => {
    this.setState({ participantName: event.target.value })
  };

  updateParticipantLastName = (event) => {
    this.setState({ participantLastName: event.target.value })
  };

  updateParticipantEmail = (event) => {
    this.setState({ participantEmail: event.target.value })
  };

  updateParticipantCity = (event) => {
    this.setState({ participantCity: event.target.value })
  };

  updateParticipantDate = (date) => {
    let formattedDate = date.format('YYYY-MM-DD');
    this.setState({ participantDate: formattedDate })
  };

  mediaStreamSuccess = () => {
    this.setState({ cameraStatus: true });
    this.activateErrorModal(false, 'error');
  };

  mediaStreamError = () => {
    this.setState({ cameraStatus: false });
    this.activateErrorModal(true, 'error');
  };

  dataURItoBlob = async (dataURI) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[ 0 ].indexOf('base64') >= 0)
      byteString = atob(dataURI.split(',')[ 1 ]);
    else byteString = unescape(dataURI.split(',')[ 1 ]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[ 0 ].split(':')[ 1 ].split(';')[ 0 ];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
      ia[ i ] = byteString.charCodeAt(i);
    }

    return new Blob([ia], { type: mimeString });
  };

  prepareDataBeforeSubmit = () => {
    //processes data and triggers submit method
    //change status to processing and add spinner
    this.props.updateSubmitting(true);
    this.activateErrorModal(true, 'submit');
    const formData = new FormData();

    if (this.state.frontSideImage) {
      const frontSideBlob = this.dataURItoBlob(this.state.frontSideImage);
      frontSideBlob
        .then((responseBlob) => {
          formData.append('frontSideImage', responseBlob, 'frontSideImage.jpg');
        })
        .catch((err) => {
          console.log('Error occurred during FrontSide Image processing');
        });
    }

    if (this.state.backSideImage) {
      const backSideBlob = this.dataURItoBlob(this.state.backSideImage);
      backSideBlob
        .then((responseBlob) => {
          formData.append('backSideImage', responseBlob, 'backSideImage.jpg');
        })
        .catch((err) => {
          console.log('Error occurred during BackSide Image processing');
        });
    }

    if (this.state.imagesArray.length >= 3) {
      this.state.imagesArray.forEach((baseImage, i) => {
        const processedImage = this.dataURItoBlob(baseImage);
        processedImage
          .then((responseBlob) => {
            formData.append(
              'userImages[]',
              responseBlob,
              'faceImage_' + i + '.jpg',
            );
          })
          .catch((err) => {
            console.log('Error occurred during BackSide Image processing');
          });
      });
    }

    formData.set('participantName', this.state.participantName);
    formData.set('participantLastName', this.state.participantLastName);
    formData.set('participantEmail', this.state.participantEmail);
    formData.set('participantCity', this.state.participantCity);
    formData.set('participantCountryName', this.state.participantCountry.name);
    formData.set('participantCountryDialCode', this.state.participantCountry.dial_code);
    formData.set('participantCountryCode', this.state.participantCountry.code);
    formData.set('participantDate', this.state.participantDate);

    this.submitData(formData);
  };

  submitData = (formData = null) => {
    const data = {
      name: 'Nanco Faked Data',
    };

    let outputData = formData ? formData : data;

    const submitRequest = makePostRequest('sendVerificationImages', this.props.token, outputData, true);

    submitRequest
      .then(response => {

        if (response.status === 200 && response.data.status) {
          this.setState({ submitFinished: true });
          this.props.updateSubmitting(false);
          let successObj = {
            title: this.state.languageData.submitSuccessful,
            text: this.state.languageData.submitSuccessfulText,
          };
          this.updateErrorModalText(successObj);
          this.props.removeSessionToken();
          this.setState({ view: 'thank-you' });

          setTimeout(() => {
            this.activateErrorModal(false, 'error');
          }, 1000);
        }
      })
      .catch((err) => {
        this.setState({ submitFinished: true });
        this.props.updateSubmitting(false);
        console.log(err);
        let errObj = {
          title: this.state.languageData.submitFailed,
          text: this.state.languageData.submitFailedText,
        };
        this.updateErrorModalText(errObj);
      });
  };

  closeTut = () => {
    this.setState({ idTutShow: false });
  };

  updateModalType = (type) => {
    this.props.updateModalType(type);
  };

  clickMethod = (type) => {
    if (!this.props.open) {
      this.props.updateModalType(type);
      this.props.openModal(true);
    }
  };

  handleSmallImageClick = (image) => {
    this.handleImageClick(image);
  };

  handleImageClick = (image) => {
    this.activateErrorModal(true, 'image');
    this.props.updateModalPicture(image);
  };

  render() {
    const { classes, activeLanguage, introductionText } = this.props;
    const view = this.state.view;
  

    let submitButtonText = this.state.submitFinished ? 'Done!' : 'Submit';
    let submitButtonClass = this.state.submitFinished ? 'MuiButton-sizeLargeSubmit' : 'MuiButton-sizeLarge';

    let cardHolder, imagesHolder, cameraHolder, formHolder;

    cameraHolder = (
      <CameraContainer
        key={this.state.videoDeviceID}
        videoDeviceID={this.state.videoDeviceID}
        updateVideoDeviceID={this.updateVideoDeviceID}
        videoDevices={this.state.videoDevices}
        processCurrentImage={this.setCurrentImage}
        processCurrentAction={this.setCurrentAction}
        mediaStreamSuccess={this.mediaStreamSuccess}
        mediaStreamError={this.mediaStreamError}
        view={view}
        updateView={this.changeView}
        frontSideImage={this.state.frontSideImage}
        backSideImage={this.state.backSideImage}
        imagesArray={this.state.imagesArray}
        submitFinished={this.state.submitFinished}
        submitButtonText={submitButtonText}
        submitButtonClass={submitButtonClass}
        prepareDataBeforeSubmitCallback={this.prepareDataBeforeSubmit}
        activeLanguage={activeLanguage}
      />
    );

    cardHolder = (
      <div>
        <BigImageContainer
          imageSrc={this.state.frontSideImage}
          type={'frontside'}
          dataCallback={this.deleteIDImageFromChild}
          handleImageClick={this.handleImageClick}
        />
        <BigImageContainer
          imageSrc={this.state.backSideImage}
          type={'backside'}
          dataCallback={this.deleteIDImageFromChild}
          handleImageClick={this.handleImageClick}
        />
      </div>
    );

    imagesHolder = (
      <div style={{ display: 'flex', justifyContent: 'center' }}>

        <List className={classes.listUL}>
          {
            this.state.imagesArray.map((image, key) => {
              return (
                <ListItem
                  className={classes.listEl}
                  key={'image-preview-key-' + key}
                  justify="between"
                  alignItems="center"
                >
                  <img
                    style={{ width: '130px', height: 'auto', cursor: 'pointer' }}
                    src={image}
                    alt={'image-preview-key-' + key}
                    onClick={() => this.handleSmallImageClick(image)}
                  />
                  <IconButton
                    className={classes.deleteIcon}
                    onClick={() => this.deleteSingleImageFromArray(key)}
                    aria-label="delete"
                    color="primary"
                  >
                    <DeleteOutlinedIcon fontSize="small"/>
                  </IconButton>
                </ListItem>
              );
            })
          }
          {
            this.state.imagesArray.length === 0 ? (
              <li className={classes.emptyHolder}>
                <FaceIcon style={{ color: 'white', fontSize: '45px' }}/>
              </li>
            ) : ('')}
          {
            this.state.imagesArray.length <= 1 ? (
              <li className={classes.emptyHolder}>
                <FaceIcon style={{ color: 'white', fontSize: '45px' }}/>
              </li>
            ) : ('')}
          {
            this.state.imagesArray.length <= 2 ? (
              <li className={classes.emptyHolder}>
                <FaceIcon style={{ color: 'white', fontSize: '45px' }}/>
              </li>
            ) : ('')
          }
        </List>
      </div>
    );

    formHolder = (
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={12}>
          <FormControl>
            <div>
              <TextField
                className={classes.formFields}
                defaultValue={this.state.participantName}
                onChange={(e) => this.updateParticipantName(e)}
                label={this.state.languageData.formName}
                InputLabelProps={{ style: { color: '#fafafa' } }}/>
              <TextField
                className={classes.formFields}
                defaultValue={this.state.participantLastName}
                onChange={(e) => this.updateParticipantLastName(e)}
                label={this.state.languageData.formLastName}
                InputLabelProps={{ style: { color: '#fafafa' } }}/>
              <TextField
                className={classes.formFields}
                defaultValue={this.state.participantEmail}
                onChange={(e) => this.updateParticipantEmail(e)}
                label={this.state.languageData.formEmail}
                InputLabelProps={{ style: { color: '#fafafa' } }}/>
            </div>
            <div>
              <TextField
                className={classes.formFields}
                defaultValue={this.state.participantCity}
                onChange={(e) => this.updateParticipantCity(e)}
                label={this.state.languageData.formCity}
                InputLabelProps={{ style: { color: '#fafafa' } }}/>
              <Autocomplete
                id="combo-box-demo"
                options={COUNTRIES}
                className={classes.inputRoot}
                style={{ width: 215, display: 'inline-flex', verticalAlign: 'top', }}
                getOptionLabel={(option) => option.name}
                closeIcon={<CloseIcon fontSize="small" color='primary' />}
                value={this.state.participantCountry}
                onChange={(event, newValue) => {
                  this.setParticipantCountry(newValue);
                }}
                renderInput={(params) =>
                  <TextField
                    {...params}
                    InputLabelProps={{ style: { color: '#fafafa' } }}
                    label={this.state.languageData.formCountry} />
                }
              />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  disableFuture
                  openTo="year"
                  format="YYYY-MM-DD"
                  label="Date of birth"
                  views={["year", "month", "date"]}
                  className={classes.formFields}
                  InputLabelProps={{ style: { color: '#fafafa' } }}
                  value={this.state.participantDate}
                  onChange={this.updateParticipantDate}
                />
              </MuiPickersUtilsProvider>
            </div>
          </FormControl>

        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Button
            onClick={this.prepareDataBeforeSubmit}
            variant="outlined"
            size="small"
            disabled={
              !this.state.participantName ||
              !this.state.participantLastName ||
              !this.state.participantEmail ||
              !this.state.participantCity ||
              !this.state.participantCountry ||
              !this.state.participantDate}
            className={submitButtonClass}
            color="primary"
          >
            {submitButtonText}
          </Button>
        </Grid>
      </Grid>
    );

    const gdprGreetingString = activeLanguage === 'en' ? this.state.languageData.gdprWarning : this.state.languageData.gdprWarning;

    const thankYouStringRender = activeLanguage === 'en' ? this.props.thankYouText.en : this.props.thankYouText.de;

    const cameraIntroduction = activeLanguage === 'en' ? introductionText.en : introductionText.de;

    return (
      <div className="Content" style={{ height: '90vh' }}>
        {
          //intro
          //here we show logo and start button

          //tutorial
          //starts after clicking on start button from intro section
          view === 'intro' || view === 'tutorial' ? (
            <div className="logoBig">
              <img src={logoBig} alt="logo" className={classes.root}/>
              {view === 'intro' ? (
                <div
                  style={{
                    color: '#fff',
                    position: 'absolute',
                    bottom: '5%',
                    left: '50%',
                    transform: 'translateX(-50%)',
                  }}
                >
                  <Button
                    className={classes.gdprBtn}
                    variant="outlined"
                    disableElevation={true}
                    color="primary"
                    style={{ marginRight: 10, color: '#fafafa' }}
                    disabled={!this.state.tokenData}
                    onClick={() => this.clickMethod('gdpr')}
                  >
                    GDPR
                  </Button>
                  <p>{gdprGreetingString}</p>
                </div>
              ) : ('')}
            </div>
          ) : ('')
        }

        {
          //TODO: move this in separate component
          //Navigation items
          (view === 'takingID' || view === 'takingImages' || view === 'dataForm') &&
          <Grid container spacing={5} className={classes.viewChange}>
            <Grid item xs={12} sm={12} md={12}>
              <IconButton
                onClick={() => this.changeView('takingID')}
                color="primary"
                className={view === 'takingID' ? classes.borderActive : ''}
              >
                <FeaturedPlayListIcon style={{ fontSize: 40 }}/>
              </IconButton>

              <IconButton
                onClick={() => this.changeView('takingImages')}
                disabled={!this.state.frontSideImage && !this.state.backSideImage}
                style={{ color: '#fff' }}
                className={view === 'takingImages' ? classes.borderActive : ''}
              >
                <PersonIcon style={{ fontSize: 40 }}/>
              </IconButton>

              <IconButton
                onClick={() => this.changeView('dataForm')}
                disabled={this.state.imagesArray.length < 3}
                style={{ color: '#fff' }}
                className={view === 'dataForm' ? classes.borderActive : ''}
              >
                <FormatListBulletedIcon style={{ fontSize: 40 }}/>
              </IconButton>
            </Grid>
          </Grid>
        }

        {
          //taking pictures
          //starts when clicking start or by closing the tutorial modal
          view === 'takingID' || view === 'takingImages' ? (
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={6}>
                {
                  view === 'takingID' && this.state.idTutShow === true &&
                  <div className={classes.idTut}>
                    <div style={{ padding: '0 10px', margin: 0, color: '#fafafa' }}>
                      {parse(cameraIntroduction ? cameraIntroduction : '')}
                    </div>
                    <IconButton
                      aria-label="delete"
                      className={classes.idTutClose}
                      onClick={() => this.closeTut(false)}
                    >
                      <CloseIcon style={{ fontSize: 30 }}/>
                    </IconButton>
                  </div>
                }
                {cameraHolder}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                {view === 'takingID' ? cardHolder : ''}
                {view === 'takingImages' ? imagesHolder : ''}
              </Grid>
            </Grid>
          ) : (
            view === 'dataForm' ? formHolder : ''
          )
        }

        {
          view === 'thank-you' ? (
            <div className={classes.thankYou}>
              {parse(thankYouStringRender ? thankYouStringRender : '')}
            </div>
          ) : ('')
        }
      </div>
    );
  }
}

export default withStyles(useStyles)(Content);