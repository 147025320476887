import React, { Component } from 'react';
import ModalSmall from './ModalSmall';
import ModalBig from './ModalBig';

class Modal extends Component {

  viewCallback = (callback) => {
    this.props.currentView(callback);
  };

  updateActiveLanguage = (data) => {
    this.props.updateActiveLanguage(data);
  };
  
  updateModalType = (type) => {
    this.props.updateModalType(type);
  };

  updateGdprAccepted = (data) => {
    this.props.updateGdprAccepted(data);
  };

  updateSubmitting = (data) => {
    this.setState({ submitting: data });
  };

  updateActivateErrorModal = (data) => {
    this.props.updateActivateErrorModal(data);
  };
  
  
  render() {

    return (
      <div>
        <ModalSmall
          view={this.props.view}
          activateErrorModal={this.props.activateErrorModal}
          updateActivateErrorModal={this.updateActivateErrorModal}
          errorModalTextData={this.props.updateErrorModalText}
          type={this.props.type}
          activeLanguage={this.props.activeLanguage}
          submitting={this.props.submitting}
          updateSubmitting={this.updateSubmitting}
          modalPicture={this.props.modalPicture}
        />
        <ModalBig
          openModal={this.props.openModal}
          open={this.props.open}
          view={this.props.view}
          currentView={this.viewCallback}
          alternativeModal={this.props.alternativeModal}
          gdprText={this.props.gdprText}
          activeLanguage={this.props.activeLanguage}
          updateActiveLanguage={this.updateActiveLanguage}
          modalType={this.props.modalType}
          updateModalType={this.updateModalType}
          gdprAccepted={this.props.gdprAccepted}
          updateGdprAccepted={this.updateGdprAccepted}
        />
      </div>
    );

  }
}

export default Modal;