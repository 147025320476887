import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withStyles } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from 'react';
import Webcam from 'react-webcam';
import Button from '@material-ui/core/Button';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import FlipCameraIosIcon from '@material-ui/icons/FlipCameraIos';
import personIcon from '../../assets/personIcon.png';
import Tooltip from '@material-ui/core/Tooltip';
import EnglishJson from '../../languages/English';
import GermanJson from '../../languages/German';

const videoConstraints = {
  width: window.innerWidth >= 480 ? 600 : 320,
  height: window.innerWidth >= 480 ? 500 : 280,
  //facingMode: 'user',
};

const useStyles = (theme) => ({
  captureButton: {
    [ theme.breakpoints.down('xs') ]: {
      width: '100px',
    },
  },
  formControl: {
    marginLeft: '8px',
    border: '1px solid rgba(250,250,250,0.5)',
    borderRadius: '100%',
    height: '54px',
    width: '54px',
    [ theme.breakpoints.down('sm') ]: {
      height: '50px',
      width: '50px',
      marginLeft: '4px !important',
      fontSize: '11px',
      lineHeight: '35px',
    },
  },
  icon: {
    fill: '#fff',
    fontSize: '26px',
    right: '14px',
    top: '14px',
    [ theme.breakpoints.down('sm') ]: {
      right: '12px',
      top: '12px',
    },
  },
  selectField: {
    color: '#fafafa',
    fontSize: '14px',
    lineHeight: '24px',
    [ theme.breakpoints.down('sm') ]: {
      fontSize: '11px',
      lineHeight: '11px',
    },
  },
});

const WebcamCapture = (props) => {
  const webcamRef = React.useRef(null);

  const personalCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    sendData(imageSrc);
  };

  const sendData = (base64) => {
    props.processCurrentImage(base64);
  };

  const changeView = (view) => {
    props.updateView(view);
  };

  const prepareDataBeforeSubmitCallback = () => {
    props.prepareDataBeforeSubmitCallback();
  };

  const mediaStreamSuccess = () => {
    props.mediaStreamSuccess();
  };

  const mediaStreamError = () => {
    props.mediaStreamError();
  };

  const changeCameraTrigger = (event) => {
    props.updateVideoDeviceID(event.target.value);
  };

  const {
    contentView, frontSideImage, backSideImage, imagesArray, submitFinished, submitButtonText,
    submitButtonClass, videoDeviceID, videoDevices, classes, activeLanguage,
  } = props;

  const languageData = activeLanguage === 'en' ? EnglishJson : GermanJson;

  return (
    <>
      {
        contentView !== 'dataForm' ?
          (
            <div style={{ 
                  position: 'relative',
                  display:'flex',
                  alignItems: 'center',
                  justifyContent:'center',
                  flexDirection:'column'
                  }}>
              <Webcam
                audio={false}
                width={window.innerWidth >= 480 ? 600 : 290}
                height={window.innerWidth >= 480 ? 500 : 280}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={!videoDeviceID ?
                  videoConstraints :
                  {
                    width: window.innerWidth >= 480 ? 600 : 320,
                    height: window.innerWidth >= 480 ? 500 : 280,
                    deviceId: videoDeviceID,
                  }}
                onUserMedia={mediaStreamSuccess}
                onUserMediaError={mediaStreamError}
                style={{
                  border: '1px solid white',
                  display: 'inline-block',
                  boxSizing: 'border-box',
                  position: 'relative',
                  zIndex: '9',
                  minHeight: window.innerWidth >= 480 ? '450px' : '254px',
                  height: window.innerWidth >= 480 ? 'auto' : '254px',
                }}
              />
              <img src={personIcon} alt="imgModal"
                   style={{ position: 'absolute', top: '48%', left: '50%', transform: 'translate(-50%, -50%)' }}/>

              <Grid container spacing={1}
              style={{
                width: window.innerWidth >= 480 ? 600 : 290,
                marginTop: 10
              }}
              >
                <Grid item xs={4} sm={4} md={4}>
                  <Tooltip
                    disableFocusListener
                    disableTouchListener
                    enterDelay={1500}
                    title={languageData.tooltipTakePicture}
                  >
                    <Button
                      onClick={personalCapture}
                      variant="outlined"
                      color="primary"
                      size="small"
                      className={classes.captureButton}
                    ><PhotoCameraIcon style={{ fontSize: '2.5em' }}/></Button>
                  </Tooltip>
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                  {
                    videoDevices.length > 1 && (
                      <FormControl
                        variant="outlined"
                        className={classes.formControl}
                      >
                        <Select
                          labelId="camera-select-label"
                          id="camera-select"
                          className={classes.selectField}
                          IconComponent={FlipCameraIosIcon}
                          inputProps={{
                            classes: {
                              icon: classes.icon,
                            },
                          }}
                          value=''
                          onChange={changeCameraTrigger}
                        >
                          {videoDevices.map((singleDevice, i) => (
                            <MenuItem
                              value={singleDevice.deviceId}
                              key={singleDevice.deviceId}
                            >{singleDevice.label ? singleDevice.label : 'Camera ' + (i + 1)}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )
                  }
                </Grid>

                <Grid item xs={4} sm={4} md={4}>
                  {
                    contentView === 'takingID' &&
                    <Button
                      onClick={() => changeView('takingImages')}
                      disabled={!frontSideImage || !backSideImage}
                      classes={{
                        disabled: 'button-disabled',
                      }}
                      color="primary"
                      variant="text"
                    >
                      {languageData.nextPage} <ArrowForwardIcon/>
                    </Button>
                  }
                  {
                    contentView === 'takingImages' ? (
                      <Button
                        onClick={() => changeView('dataForm')}
                        disabled={imagesArray.length <= 2 || submitFinished}
                        classes={{
                          disabled: 'button-disabled',
                        }}
                        color="primary"
                        variant="text"
                      >
                        {languageData.nextPage} <ArrowForwardIcon/>
                      </Button>
                    ) : ('')
                  }
                </Grid>
              </Grid>
            </div>
          ) :
          <Button
            onClick={prepareDataBeforeSubmitCallback}
            variant="outlined"
            size="small"
            className={submitButtonClass}
            color="primary"
          >
            {submitButtonText}
          </Button>
      }
    </>
  );
};

//export default WebcamCapture;
export default withStyles(useStyles)(WebcamCapture);
