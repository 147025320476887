import React, { Component } from 'react';
import { HeaderContainer } from '../../components/header';

class Header extends Component {

  updateActiveLanguage = (data) => {
    this.props.updateActiveLanguage(data);
  };

  updateModalType = (type) => {
    this.props.updateModalType(type);
  };

  render() {
    return (
      <div className="Header" style={{ position: 'relative' }}>
        <HeaderContainer
          openModal={this.props.openModal}
          open={this.props.open}
          intro={this.props.intro}
          activeLanguage={this.props.activeLanguage}
          updateActiveLanguage={this.updateActiveLanguage}
          
          modalType={this.props.modalType}
          updateModalType={this.updateModalType}
          token={this.props.token}
        />
      </div>
    );
  }
}

export default Header;
