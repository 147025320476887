import React,{Component} from 'react';
import { withStyles } from '@material-ui/core/styles';


const modalStyles = theme => ({
    logoMob: {
        [ theme.breakpoints.down('sm') ]: {
            width: '35%',
        },
    }
});


class Logo extends Component {

    render(){

        const {img, edge, alt, classes } = this.props;

        return(
            <img src={img} edge={edge} alt={alt} className={classes.logoMob} />
        )
    }

}

export default withStyles(modalStyles)(Logo);