import React from 'react';
import HomePage from './pages/HomePage';
import './App.css';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import { BrowserRouter, Route } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    maxWidth: '1490px',
  },
  app: {
    backgroundColor: '#363636',
    textAlign: 'center',
  },
});

function App() {
  const myClass = useStyles();
  return (
    <BrowserRouter>
      <div className={myClass.app}>
        <Container classes={{ maxWidthLg: myClass.root }}>
          <Route
            exact
            path='/'
            render={props => (
              <HomePage {...props}/>
            )}
          />
        </Container>
      </div>
    </BrowserRouter>
  );
}

export default App;
